import React from 'react';
import Link from '@material-ui/core/Link';
import { BottomLinksContainer, Text, OptionContainer } from './style';
 

const BottomLinks = ({linkArray}) => {
    const renderLinks = (links) => {
        const returnArray = links.map((link,index) => { 
            return(
                <OptionContainer key={index + link.title}>
                    <Link  key={index}>{ link.title }</Link>
                    <Text>{ link.text }</Text>
                </OptionContainer>
            )
        })
        return returnArray
    };

    return (
        <BottomLinksContainer >
            <h4>{linkArray.title}</h4>
            {renderLinks(linkArray.linkTitles)}
        </BottomLinksContainer>
    )
}

export default BottomLinks;
