import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Gallery from '../Gallery';



const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    width: '100%',
    backgroundColor: props => props.cardColor,
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      width: '90%',
      flexDirection: 'row',
    },
    alignSelf: 'center',
    direction: props => props.cardDirection
  },
  media: {
    width: '100vw',
    objectFit: 'contain',
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      height: '400px',
      width: '50%'
    },
  },
  button: {
    width: '100%',
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      width: '50%'
    },
  },
  para: {
    fontSize: '20px',
    ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '16px'
    },
  }
});

const MyCard = ({ label, text, photo, ...props }) => {
  const CustomGallery = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
}
`;
  const classes = useStyles({ ...props });
  const img = [require(`../${photo}`)]
    return (
      <Card className={classes.root}>  
        
        {/* <CardMedia
          className={classes.media}
          component="img"
          alt="Contemplative Reptile"
          src={require(`../${photo}`)}
        /> */}
        <CustomGallery>
            <Gallery imagesList={img}/>
        </CustomGallery>
        <CardActionArea
          className={classes.button}>
          <CardContent>
            <Typography style={{ direction: 'rtl'}} gutterBottom variant="h4" component="h2">
              {label}
            </Typography>
            <Typography align='right' variant="body2" color="textSecondary" component="p" className={classes.para}>
              {text}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
}

export default MyCard;
