import React from 'react';
import { useState } from 'react';
import ReactMapGL, { setRTLTextPlugin } from 'react-map-gl';
import { isMobile } from 'react-device-detect';

const CustomMap = () => {
  const [viewport, setViewport] = useState({
    width: isMobile ? '90%' : '50%',
    height: 400,
    latitude: 32.10649,
    longitude: 34.93941,
    zoom: 16,
  });
  return (
    <ReactMapGL
      style={{ marginTop: '15px' }}
      {...viewport}
      mapboxApiAccessToken='pk.eyJ1IjoidG1yeWFua28iLCJhIjoiY2tmeHB3MTFmMnFxejJ5cGFmbHkzcjBobCJ9._nzo_CI6jb8zATHTWK1d5Q'
      onViewportChange={(nextViewport) => setViewport(nextViewport)}
    />
  );
};

export default CustomMap;
