import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import photo from '../ClientList/images/ISO.jpg';
import photo1 from '../ClientList/images/telfire.jpg';
import photo2 from '../ClientList/images/telfire2.jpg';
import photo3 from '../ClientList/images/tkanim.jpg';
const index = () => {
  return (
    <Carousel>
      <Carousel.Item style={{ height: '60vh', width: '80vw' }}>
        <img
          className='d-block w-100'
          style={{ height: '100%', width: '100%' }}
          src={photo}
          alt='First slide'
        />
      </Carousel.Item>
      <Carousel.Item style={{ height: '60vh', width: '80vw' }}>
        <img
          className='d-block w-100'
          style={{ height: '100%', width: '100%' }}
          src={photo1}
          alt='Third slide'
        />
      </Carousel.Item>
      <Carousel.Item style={{ height: '60vh', width: '80vw' }}>
        <img
          className='d-block w-100'
          style={{ height: '100%', width: '100%' }}
          src={photo2}
          alt='Third slide'
        />
      </Carousel.Item>
      <Carousel.Item style={{ height: '60vh', width: '80vw' }}>
        <img
          className='d-block w-100'
          style={{ height: '100%', width: '100%' }}
          src={photo3}
          alt='Third slide'
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default index;
