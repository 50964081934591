import React from 'react';
import styled from 'styled-components';

const MyTitle = styled.div`
    display: flex;
    font-size: 40px;
    color: ${props => props.inputColor || "black"};
`;

const Title= ({inputColor,children}) => {
    return (
        <MyTitle inputColor={inputColor}>
            {children}
        </MyTitle>
    )
}

export default Title;
