import styled from 'styled-components';

export const ClientCont = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 80px;
  align-items: center;
`;

export const ImgList = styled.div`
  margin-top: 35px;
  display: flex;
  @media (max-width: 768px) {
    overflow-x: auto;
  }
`;

export const ImgCont = styled.img`
  height: 20%;
  width: 45%;
  // transform: ${(props) => (props.deg ? `rotate(${props.deg}deg)` : '')};
  cursor: grab;
  @media (min-width: 768px) {
    width: 65%;
  }
`;
export const BigImg = styled.img`
  //   transform: ${(props) => (props.deg ? `rotate(${props.deg}deg)` : '')};
  height: 100%;
  width: 100%;
`;
export const ImgDiv = styled.div`
  height: 100%;
  width: 100%;
`;
