import styled, { css } from 'styled-components';

const LangStyle = (props) => {
  const dir = 'rtl';
  return css`
    direction: ${dir};
  `;
};
export const FooterContainer = styled.div`
  display: flex;
  ${LangStyle}
  flex-direction: column;
  justify-content: center;
  // background-color: #3f51b5;
  margin-top: 10px;
  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;
export const FooterLinkContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  @media (min-width: 768px) {
    justify-content: space-between;
    align-items: center;
  }
`;

export const BottomLinksContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 50px;
  }
`;

export const FooterButton = styled.div`
  align-self: center;
  width: max-content;
`;
