import React from 'react';

const HandleFocusDiv = ({children}) => {
    const newArr = React.Children.map(children, (child) => {
        console.log(document.getElementById("navBtn"));

        return child;
      });
    return (
        <div>
            {newArr}
        </div>
    )
}

export default HandleFocusDiv;
