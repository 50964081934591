import React from 'react';
import Nav from './components/Nav';
import Footer from './components/Footer';
import './App.css';
import Routing from './components/Routing';

const App = ({lang}) => {

  
  return (
    <div className="App">
      <Nav lang={lang}/>
      <Routing lang={lang}/>
      <Footer lang={lang}/>      
    </div>
  );
}

export default App;
