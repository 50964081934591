import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'mobile-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import MenuIcon from '@material-ui/icons/Menu';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { LinksArr } from './constants/constants';
import { Link } from 'react-scroll';
import logo from './images/logo.png';


const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
      cursor: 'pointer'
    },
    MuiToolbar: {
        direction: 'rtl',
        justifyContent: 'space-between',
        color: 'red',
        backgroundColor: 'white'
    },
    MuiPopover: {
        textAlign: props => LinksArr['he'].textAlign
    }
  }));

  
  
const Nav = ({ handleNavLinkClick, history, ...props}) => {
    const classes = useStyles({...props});
    const [openPop, setOpenPop] = useState(null);

    const renderNavLinks = () => {
        const dir = LinksArr['he'].links;

        const linkArr = dir.map((link,index) => {
        return <Typography key={index + link.title} className={classes.typography} >
                    <Link onClick={ handleClose } id='navBtn' to={link.to} spy={true} smooth={true} offset={isMobile ? 150 : -160} duration={500}>{link.title}</Link>
                </Typography>
        })
        
        return linkArr;
    }

    const handleClick = () => {
        setOpenPop(true);
    }
    const handleClose = () => {
        setOpenPop(null);
      };
    const open = Boolean(openPop);
        return (
            <AppBar position='sticky'>  
                <Toolbar className={classes.MuiToolbar} {...props}>
                {/* {renderNavLinks()} */}
                    {isMobile ?
                        <>
                        <IconButton onClick={handleClick}>
                        <MenuIcon />
                    </IconButton>
                    <Popover 
                    className={classes.MuiPopover}
                    anchorOrigin={{
                        vertical: 85,
                        horizontal: 'right',
                      }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
                    anchorEl={openPop} open={open} onClose={handleClose}>
                        {renderNavLinks()}
                        </Popover> </>:
                    renderNavLinks()}
                    <IconButton onClick={ () => history.push("/")}>
                        <img src={logo} style={{height: '120px', width: '180px'}}/>
                        {/* <HomeWorkIcon onClick={ () => history.push("/")}/> */}
                    </IconButton>
                </Toolbar>
            </AppBar>
    )
}

export default withRouter(Nav);
