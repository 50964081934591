import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Main from './Main';
import Card from './Card';

const Routing = ({ lang, id }) => {
  return (
    <div>
      <Switch>
        <Route exact path='/'>
          <Main lang={lang} id={id} />
        </Route>
        {/* <Route exact path="/proj1">
                    <Card label='some' text="fff" photo='Projects/images/handshake.jpg' lang={lang}/>
                </Route>
                <Route exact path="/proj2">
                    <Card label='some' text="fff" photo='Projects/images/handshake.jpg' lang={lang}/>
                </Route>
                <Route exact path="/proj3">
                    <Card label='some' text="fff" photo='Projects/images/handshake.jpg' lang={lang}/>
                </Route>
                <Route exact path="/proj4">
                    <Card label='some' text="fff" photo='Projects/images/handshake.jpg' lang={lang}/>
                </Route> */}
      </Switch>
    </div>
  );
};

export default Routing;
