export const CARD_LIST = [
  {
    label: 'מערכות גילוי אש ועשן:',
    text: `
        חברתנו מתמחה בתכנון והתקנה של מערכות גילוי אש 
        על פי תקן ישראלי 1220 חלק 3 בהתאם לדרישות כיבוי אש, חברות ביטוח ורישוי עסקים.
        אנו נלווה אתכם משלב ייעוץ ותכנון עד לשלב התקנה והפעלה סופית של המערכת כולל אישור מכון התקנים.
        התקנה מתבצעת ע"י צוותי התקנה מקצועיים ומוסמכים ע"י מכון התקנים.
        
        ביצוע בדיקה שנתית לתקינותה של מערכת גילוי האש בהתאם לתקן ישראלי 1220 חלק 11.
        
        `,
    photo: 'CardList/images/fireSystem.jpeg',
    cardDirection: 'rtl',
    cardColor: 'lightgray',
  },
];
export const CARD_LIST1 = [
  {
    label: 'מערכת כריזה משולבת וטלפון כבאים:',
    text: `
        חברתנו מתמחה בתכנון והתקנה של מערכות כריזה משולבות לפי תקן 1220 חלק 2/3 וטלפון כבאים.
        המערכות מאפשרת השמעת אזעקה, הודעות ושיחה עם הכבאים במבנה.
        ביצוע בדיקה שנתית לתקינות המערכות עפ"י תקן
        
        `,
    photo: 'CardList/images/chechFireSystem.jpg',
    cardDirection: 'ltr',
    cardColor: 'white',
  },
];

export const CARD_LIST2 = [
  {
    label:
      'כיבוי אוטומטיים באבקה/גז עבור חדרים עם סיכון גבוה ולוחות חשמל בהתאם לתקן ישראלי 1597',
    text: `
        חברתנו תספק לך ייעוץ, הדרכה, תכנון והתקנה של מערכות כיבוי אש לחללים כגון: חדרים נקיים, ארכיונים, חדרי גנרטור, חדרי תקשורת ועוד.
        מכיוון שכיבוי האש אינו כולל שימוש במים, לא נגרמים נזקי מים לחלל ולתכולתו בעת כיבוי השריפה.
        ביצוע בדיקה שנתית לתקינותה של המערכת בהתאם לתקנים.
        ומתן אישור בהתאם לדרישות כיבוי אש, חברות ביטוח ורישוי עסקים
        
        ביצוע בדיקה שנתית למערכות כיבוי אוטומטי בגז/אירוסול בלוחות חשמל בהתאם לתקן ישראלי 1597 ו/או 5210
        
    `,
    photo: 'CardList/images/pouderFire.png',
    cardDirection: 'rtl',
    cardColor: 'lightgray',
  },
];
export const CARD_LIST3 = [
  {
    label: 'מערכת ספרינקלרים:',
    text: `
        חברתנו מתמחה בתכנון והתקנה של מערכות ספרינקלרים
        על פי תקן NFPA-13 1596  בהתאם לדרישות כיבוי אש, חברות ביטוח ורישוי עסקים.
        אנו נלווה אתכם משלב ייעוץ ותכנון כולל הגשת תיק תכנית ותכנון לאישור מכון התקנים לפני ביצוע
        עד לשלב התקנה והפעלה סופית של המערכת כולל אישור מכון התקנים וקבלת רישיון עסק.
        התקנה מתבצעת ע"י צוותי התקנה מקצועיים ומוסמכים.
        כל הציוד נושא תו תקן UL/FM.
        
        ביצוע בדיקה שנתית לתקינותה של מערכת הספרינקלרים בהתאם לתקן 1928.
        בדיקה ותחזוקה לחדרי משאבות הגברת לחץ ספרינקלרים והידרנטים  בהתאם לתקן 1928.
        בדיקת ספיקה/אופיין רשת למים , הידרנטים ומשאבות.
        
    `,
    photo: 'CardList/images/spriklers.jpg',
    cardDirection: 'ltr',
    cardColor: 'white',
  },
];
export const CARD_LIST4 = [
  {
    label: 'ציוד כיבוי אש',
    text: `
    אצלנו תמצאו מגוון רחב של ציוד כיבוי אש בהתאם לדרישות כיבוי אש, חברות ביטוח ורישוי עסקים
    עפ"י תקן. 
    חברתנו מספקת שירותי מילוי וחידוש למטפי כיבוי אש מכל הסוגים שיפוץ, שיקום מילוי ובדיקה של ציוד הקיים ברשותכם ומתן אישור לפי תקן 129
    מילוי אבקה, צביעה מחדש, בדיקת לחץ הידרוסטטי ,דחיסת חנקן ובדיקת שעון לחץ.
    שרות מבוצע ע"י טכנאי מוסמך מטעם מכון התקנים.
    בדיקת מטפים כללית לפי תקן ישראלי 129 חלק 1.
    בדיקת גלגלון לפי תקן ישראלי 2206
    בדיקת זרנוקים לפי תקן ישראלי 365
    בדיקת ברזי שריפה / הידרטיים לפי תקן ישראלי 448 
    
    `,
    photo: 'CardList/images/endFire.png',
    cardDirection: 'rtl',
    cardColor: 'lightgray',
  },
];
export const CARD_LIST5 = [
  {
    label: 'מז"ח – מונע זרימה חוזרת לפי תקן ישראלי 4426',
    text: `
    אנו מתקינים כל סוגי מז"חים, מטפלים בתקלות ונזילות ומבצעים בדיקות תקופתיות
אשר מספקים לכם ביטחון כי הציוד תקין.
לאחר תיקון או בדיקת המערכת ינתן אישור שנתי לתקינות המז"ח ע"י טכנאי מוסמך מטעם משרד הבריאות.
העבודה מבוצעת ע"י צוות מקצועי ומוסמך באמצעות ציוד מאושר ותקני.

    `,
    photo: 'CardList/images/mazah.png',
    cardDirection: 'ltr',
    cardColor: 'white',
  },
];
export const CARD_LIST6 = [
  {
    label: 'מנדפים ומתקני סינון:',
    text: `בין היתר חברתנו מבצעת התקנת מערכות כיבוי למנדפים ומתקני סינון במטבחים . כל מערכות הכיבוי שמשווקת החברה עומדות בתקנים המחמירים ביותר בעולם, לרבות ת"י 5356 ותקן UL 300 . `,
    photo: 'CardList/images/mandaf.jpg',
    cardDirection: 'rtl',
    cardColor: 'lightgray',
  },
];
export const CARD_TEXT = [
  {
    text: `חברתנו משווקת ומתקינה מערכות לגילוי וכיבוי אש הפתוחות לתחזוקת כל החברות .
    כל הציוד והמערכות שמתקנות מתאימות לדרישות תקן ישראלי,רשויות כיבוי אש ותקנים בינלאומיים.
    התקנת כיבויים אוטומטיים בלוחות חשמל באירוסול בהתאם לתקן 5210.
    התקנת כיבוי אוטומטי בגז בלוחות חשמל וחדרי שרתים בהתאם לתקן 1597.
    התקנת מערכות גילוי אש אזוריות .
    התקנת מערכות גילוי אש אנלוגיות .
    גלאי גז למטבחים תעשייתיים שמתחברים לברז ניתוק חשמלי.
    `,
    label: 'התקנת מערכות גילוי אש ועשן בהתאם לתקן ישראלי 1220 חלק 3',
    photo: 'Projects/images/system.jpg',
    cardDirection: 'rtl',
    cardColor: 'white',
  },
];
