import React from 'react';
import Carusel from '../Carusel';
import Projects from '../Projects';
import Card from '../Card';
import CardList from '../CardList';
import ClientList from '../ClientList';
import { Element } from 'react-scroll';
// import { CARD_TEXT } from '../constants';
import { CARD_LIST, CARD_LIST1, CARD_LIST2, CARD_LIST3,CARD_LIST4, CARD_LIST5, CARD_LIST6, CARD_TEXT } from './constant';

function Main({id}) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Element id='about-us' name='about-us'>
                <Carusel title='title'/>
            </Element>
            <Element id='projects' name='projects'>
                <Projects/>           
            </Element>
            <Element id='card' name='card'>
                <CardList list={CARD_LIST} />
            </Element>
            <Element id='card1' name='card1'>
                <CardList list={CARD_LIST1} />
            </Element>
            <Element id='card2' name='card2'>
                <CardList list={CARD_LIST2} />
            </Element>
            <Element id='card3' name='card3'>
                <CardList list={CARD_LIST3} />
            </Element>
            <Element id='card4' name='card4'>
                <CardList list={CARD_LIST4} />
            </Element>
            <Element id='card5' name='card5'>
                <CardList list={CARD_LIST5} />
            </Element>
            <Element id='card6' name='card6'>
                <CardList list={CARD_LIST6} />
            </Element>
            <Element id='the-crow' name='the-crow' style={{ display: 'flex', flexDirection: 'column' }}>
                {/* <Card label="התקנת מערכות גילוי אש ועשן בהתאם לתקן ישראלי 1220 חלק 3" text={ CARD_TEXT } photo='Projects/images/system.jpg'/> */}
                <CardList list={CARD_TEXT} />
            </Element>
            <Element id='our-clients' name='our-clients'>
                <ClientList/>
            </Element>
            
        </div>
    )
}

export default Main;
