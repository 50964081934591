import React from 'react';
import { ClientCont, ImgCont, ImgList, BigImg, ImgDiv } from './style';
import Title from '../common/Title';
import photo from './images/ISO.jpg';
import photo1 from './images/telfire.jpg';
import photo2 from './images/telfire2.jpg';
import photo3 from './images/tkanim.jpg';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MobileSlider from '../MobileSlider/index';
import { isMobile } from 'react-device-detect';

import {
  createMuiTheme,
  ThemeProvider,
  useTheme,
} from '@material-ui/core/styles';

const ClientList = (props) => {
  const [clicked, setClick] = React.useState(false);
  const [image, setImage] = React.useState(photo);

  const dialogTheme = createMuiTheme({
    overrides: {
      MuiDialog: {
        paper: {
          // transform: `rotate(${bigImg}deg)`,
        },
      },
    },
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleClick = (img) => {
    setImage(img);
    setClick(!clicked);
  };

  return (
    <ClientCont value='clientList'>
      <Title>הסמכות</Title>
      {!isMobile ? (
        <ImgList>
          <ImgDiv>
            <ImgCont src={photo} onClick={() => handleClick(photo)} deg='-90' />
          </ImgDiv>
          <ImgDiv>
            <ImgCont
              src={photo1}
              onClick={() => handleClick(photo1)}
              deg='90'
            />
          </ImgDiv>
          <ImgDiv>
            <ImgCont src={photo2} onClick={() => handleClick(photo2)} />
          </ImgDiv>
          <ImgDiv>
            <ImgCont
              src={photo3}
              onClick={() => handleClick(photo3)}
              deg='-90'
            />
          </ImgDiv>
        </ImgList>
      ) : (
        <MobileSlider />
      )}
      <ThemeProvider theme={dialogTheme}>
        <Dialog
          fullScreen={fullScreen}
          // fullWidth={true}
          // maxWidth='sm'
          // fullScreen
          open={clicked}
          onClick={handleClick}
        >
          <DialogContent>
            <BigImg src={image} onClick={handleClick} />
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </ClientCont>
  );
};

export default ClientList;
