import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  ProjText,
  ProjCont,
  ProjImg,
  ImgCont,
  TitleCont,
  ProjImageDiv,
  ProjImageText,
} from './style';
import photo1 from './images/checkSys.jpg';
import photo2 from './images/fiberCloset.jpg';
import photo3 from './images/mandaf2.jpg';
import photo4 from './images/mataz2.jpg';
import Title from '../common/Title';

const Projects = ({ history }) => {
  // const handleClick = (link) => {
  //     history.push(`/${link}`)
  // };

  return (
    <ProjCont>
      <TitleCont>
        <Title>תחומי פעילות החברה:</Title>
        <ProjText>
          - ייצור ,שיווק ,תחזוקה והתקנה של ציוד כיבוי אש מטלטל כגון: ארונות
          כיבוי אש ,גלגלונים זרנוקים ועוד.
          <br />
          - שיווק ,התקנה, תחזוקה ומילוי חוזר למטפים מטלטלים בהתאם לתקן ישראלי
          129 .
          <br />
          -תכנון , התקנה ובדיקה למערכות גילוי אש בהתאם לתקן ישראלי 1220 חלק 3
          (התקנה) וחלק 11 (תחזוקה).
          <br />
          -התקנה ותחזוקה של מערכות כריזה וטלפון כבאים בהתאם לת"י 1220 חלק 3 או
          מפרט 160 של המשטרה.
          <br />
          .התקנת כיבויים אוטומטיים בגז/אבקה לארונות חשמל, חדרי שרתים וארכיונים-
          <br />
          -תכנון התקנה תחזוקה ובדיקה למערכות כיבוי אש במים (ספרינקלרים) בהתאם
          לתקן ישראלי 1596 (התקנה) ותקן 1928 (תחזוקה)
          <br />
          -התקנת מערכות כיבוי אש במים "פריאקשן" משולבות עם גלאים להגנה כפולה.
          לחדרים מיוחדים כגון חדרי שרתים וחדרי מעליות .
          <br />
          -בדיקת מונע זרימה חוזרת (מז"ח) בהתאם לדרישת משרד הבריאות ותאגידי המים.
          <br />
          -תכנון , התקנה ותחזוקה למערכות שיחרור עשן ,חלונות עשן ומפוחים.
          <br />
          -התקנה ותחזוקת מערכת לכיבוי אש במנדף בישול ומתקן סינון בהתאם לתקן
          בינלאומי UL300
        </ProjText>
      </TitleCont>
      <ImgCont>
        <ProjImageDiv>
          <ProjImg alt='some' src={photo1} />
          <ProjImageText>{/* <Title>קרא עוד</Title> */}</ProjImageText>
        </ProjImageDiv>
        <ProjImageDiv>
          <ProjImg alt='some' src={photo2} />
          <ProjImageText>{/* <Title>קרא עוד</Title> */}</ProjImageText>
        </ProjImageDiv>
        <ProjImageDiv>
          <ProjImg alt='some' src={photo3} />
          <ProjImageText>{/* <Title>קרא עוד</Title> */}</ProjImageText>
        </ProjImageDiv>
        <ProjImageDiv>
          <ProjImg alt='some' src={photo4} />
          <ProjImageText>{/* <Title>קרא עוד</Title> */}</ProjImageText>
        </ProjImageDiv>
      </ImgCont>
    </ProjCont>
  );
};

export default withRouter(Projects);
