import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const Map = withScriptjs(withGoogleMap(({props}) => {

    return (
        <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: 32.106490, lng: 34.939410 }}
      >
      <Marker position={{ lat: 32.106490, lng: 34.939410 }} />
    </GoogleMap>
    )
}))

export default Map;


