import React from 'react';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1)
    }
  }));

const theme = createMuiTheme({
    palette: {
      primary: green
    }
  });

const CustumButton = ({ children, size, variant, type }) =>  {
    const classes = useStyles();
    return (
            <ThemeProvider theme={theme}>
                <Button type={type} size='small' variant={variant} color="primary" className={classes.margin}>
                    { children }
                </Button>
            </ThemeProvider>     
    )
}

export default CustumButton;
