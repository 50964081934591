import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const BottomIcons = () => {
    return (
        <div style={{ color: 'white', alignSelf: 'center', padding: '20px' }}> 
            <a style={{ color: 'white'}} href="https://www.facebook.com/people/%D7%A4%D7%9C%D7%A1%D7%98%D7%A4%D7%99%D7%91%D7%A8-%D7%92%D7%9C-%D7%90%D7%A9-%D7%AA%D7%A2%D7%A9%D7%99%D7%95%D7%AA-%D7%A2%D7%95%D7%9E%D7%A8%D7%99/100040735688768">
                <FacebookIcon fontSize="large" /> 
            </a> 
            {/* <InstagramIcon color="default" />     */}
            <a
            style={{ color: 'white'}}
            href="mailto:galesh102@gmail.com"
            target="_self"
            data-content="galesh102@gmail.com"
            data-type="mail"
            >
                <MailOutlineIcon fontSize="large" />
            </a>     
        </div>
    )
}

export default BottomIcons;

