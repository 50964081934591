import React, { useState } from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import emailjs from 'emailjs-com';
import {
  FooterContainer,
  FooterLinkContainer,
  BottomLinksContainer,
  FooterButton,
} from './style';
import CustumButton from '../common/Button';
import Divider from '@material-ui/core/Divider';
import BottomLinks from '../BottomLinks';
import BottomIcons from '../BottomIcons';
import CustomMap from '../CustomMap/index';
import { buttonLabel, linkArray } from './constants/constants';
import axios from 'axios';

const Footer = ({ ...props }) => {
  const [from_name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  // const API_PATH = '../../subscriberform.php'
  const handleFormSubmit = (e) => {
    e.preventDefault(); //This is important, i'm not sure why, but the email won't send without it
    console.log(e.target);
    emailjs
      .sendForm(
        'service_tueuuhg',
        'template_v66ciad',
        e.target,
        'user_TOv9SUyyuHGF3GaomKGhi',
      )
      .then(
        (result) => {
          //   window.location.reload(); //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
        },
        (error) => {
          console.log(error.text);
        },
      );
    // e.preventDefault();
    // e.stopPropagation()
    // axios({
    //   method: 'post',
    //   url: `${API_PATH}`,
    //   headers: { 'content-type': 'application/json' },
    //   data: {name, phone, email}
    // })
    //   .then(result => {
    //     console.log("sent")
    //   })
    //   .catch(error => console.log("not sent"));
  };

  return (
    <div>
      <FooterContainer id='section1' {...props}>
        {/* <FooterButton>
            <CustumButton variant='contained' size="large">{buttonLabel["he"]}</CustumButton>
        </FooterButton> */}
        <Divider variant='middle' />
        <FooterLinkContainer>
          <BottomLinksContainer>
            <div>
              <BottomLinks linkArray={linkArray['he']} />
              <div style={{ marginTop: '50px' }}>
                <form
                  //   id='contact-form'
                  //   method='POST'
                  onSubmit={handleFormSubmit}
                  //   action='#'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    direction: 'rtl',
                  }}
                >
                  <FormControl>
                    <InputLabel style={{ left: 'auto' }} htmlFor='from_name'>
                      שם
                    </InputLabel>
                    <Input
                      name='from_name'
                      id='from_name'
                      value={from_name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel style={{ left: 'auto' }} htmlFor='phone'>
                      טלפון
                    </InputLabel>
                    <Input
                      id='phone'
                      name='phone'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel style={{ left: 'auto' }} htmlFor='email'>
                      אימייל
                    </InputLabel>
                    <Input
                      id='email'
                      name='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                  {/* <input type="submit" name="submit" value="שלח"/> */}
                  <FooterButton>
                    <CustumButton
                      type='submit'
                      variant='contained'
                      size='large'
                    >
                      {buttonLabel['he']}
                    </CustumButton>
                  </FooterButton>
                </form>
              </div>
            </div>

            {/* <BottomLinks linkArray={linkArray["he"]}/>
                <BottomLinks linkArray={linkArray["he"]}/> */}
            {/* <Map  
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA9mkrI8W5PjyzTg26gTugMRXavxayKJSg"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ marginTop: '20px', width: '300px', height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            /> */}
            <CustomMap />
          </BottomLinksContainer>
          <BottomIcons />
        </FooterLinkContainer>
      </FooterContainer>
    </div>
  );
};

export default Footer;
