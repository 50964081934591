export const buttonLabel = {
    en: 'contant us',
    he: 'שלח'
}

export const linkArray = {
    he: {
        title: 'פרטי התקשרות' ,
        linkTitles: [
            { title: 'משרד:', text: `רח' התעשיה 5 ת.ד 1417 ראש העין , מיקוד 4801793` },
            { title: 'טלפון:', text: "0722-20-30-22" },
            { title: 'פקס:', text: "03-9655447" }
        ]
    }
}