import React from 'react';
import styled from 'styled-components';
import Card from '../Card';

const CardList = ({ list }) => {
    
    var cardColor;
    const newCardList = list.map((card, index) => {
        cardColor = card.cardColor
        // setBColor(card.cardColor)
        return (
            <Card key={index + card.label} label={card.label} text={ card.text } photo={ card.photo } cardColor={card.cardColor} cardDirection={card.cardDirection}/>
        )
    })

        const CardListCont = styled.div`
        display: flex;
        min-height: 90vh;
        flex-direction: column;
        background-color: ${cardColor};
        justify-content: center;
    `;
    return (
        <CardListCont>
            {newCardList}
        </CardListCont>
    )
}

export default CardList;
