import React from 'react';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import pic1 from './images/firefighter.jpg';
import pic2 from './images/plumbing.jpg';
import Title from '../common/Title';

const TextContainer = styled.div`
  display: flex;
  position: absolute;
  width: 85%;
  top: 25%;
  align-items: center;
  text-align: end;
  left: 7%;
  flex-direction: column;
  font-weight: bold;
  @media (min-width: 768px) {
    top: 45%;
    justify-content: center;
    align-items: center;
    left: 11%;
    margin-right: auto;
    width: 25%;
  }
`;
const Text = styled.p`
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    //height: 255,
    display: 'block',
    overflow: 'hidden',
    width: '100%',
  },
  imgCont: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '30rem',
    ['@media (min-width:780px)']: {
      // eslint-disable-line no-useless-computed-key
      minHeight: '50rem',
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

const Carusel = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const handleClick = () => {
    console.log('some');
  };
  return (
    <div className={classes.root}>
      <AutoPlaySwipeableViews
        interval={10000}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        //index={activeStep}
        onChangeIndex={handleStepChange}
        //enableMouseEvents
      >
        <div
          style={{ backgroundImage: `url(${pic1})` }}
          className={classes.imgCont}
        />
        <div
          style={{ backgroundImage: `url(${pic2})` }}
          className={classes.imgCont}
        />
      </AutoPlaySwipeableViews>
      <TextContainer>
        <Title inputColor='#ef0e00'>פלסטפיבר גל-אש תעשיות בע"מ</Title>
        <Text>
          חברת פלסטפיבר גל-אש תעשיות בע"מ הוקמה בשנת 1995 במטרה לספק שירותים
          בתחום גילוי וכיבוי אש, מניעת דליקות, מיגון אש פאסיבי ואקטיבי. החברה
          מספקת פתרונות לדרישות רשות הכבאות בהתאם לתקנים הישראליים לסקטור הפרטי
          והציבורי כאחד. החברה בעלת היתרים ממכון התקנים ורשויות כיבוי אש. מוצרי
          החברה המותקנים על ידנו נושאים תו תקן ישראלי ותקנים בין לאומיים, על מנת
          לאפשר את ביצוע העבודה בסטנדרטיים הגבוהים ביותר ובבטיחות המרבית . החברה
          שמה דגש על אספקת מוצרים איכותיים ושירות אמין ללקוחותיה. Iso בחברתנו
          מיושמת מערכת ניהול איכות בהתאם לתקן 9001.2015 עם לקוחותינו נמנים:
          רשתות, תאגיד מים, חברת חשמל, עיריות, מפעלים, מוסדות חינוך, מסעדות,
          ועדי בתים, חברות ניהול ולקוחות פרטיים רבים
        </Text>
      </TextContainer>
    </div>
  );
};

export default Carusel;
