import styled from 'styled-components';

export const BottomLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #000000;
`;

export const Text = styled.div`
    font-size: 15px;
    margin-right: 5px;
`;

export const OptionContainer = styled.div`
    display: flex;
`;