import React from 'react'
import ImageGallery from 'react-image-gallery';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import './galleryStyle.sass';


const Gallery = ({ imagesList, deg }) => {
    const [isFullScreen, setIsFullScreen] = React.useState(false);
    const _imageGallery = React.useRef(null);
  
    React.useEffect(() => {
      const handleEsc = (event) => {
        if (event.keyCode === 27) {
          setIsFullScreen(false);
        }
      };
  
      window.addEventListener('keydown', handleEsc);
  
      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }, []);
    const hanleCloseGallery = () => {
        _imageGallery.current.exitFullScreen();
        setIsFullScreen(false);
      };
    const handleGalleryClick = () => {
          console.log("test")
        if (!isFullScreen) {
          _imageGallery.current.fullScreen();
          setIsFullScreen(true);
        } 
      };
    const imgsArrFix = imagesList.map((img) => {
        const fixHttpsStr = (img.includes('https')) ? `components/${img}` : `https://${img.split('http')[0]}`;
        
        return ({
          original: img
        //   thumbnail: img
        });
      });

    return (
      <div >
            {isFullScreen ? 
        <IconButton onClick={ hanleCloseGallery }>
          <HighlightOffIcon 
          fontSize="large"
          htmlColor="white"   
          style={ { 
          position: 'fixed', top: '150px', right: '5px', zIndex: '11111'
          } } />
        </IconButton>
         :
       null}
        <ImageGallery
          ref={_imageGallery}
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={ false }
            useBrowserFullscreen={false}
            onClick={ handleGalleryClick }
                items={imgsArrFix} />    
        </div>
              
    )
}

export default Gallery
