export const LinksArr = {
  he: {
    links: [
      { title: 'מי אנחנו', to: 'about-us' },
      { title: 'מוצרי פיברגלס', to: 'fiber' },
      { title: 'ציוד כיבוי אש', to: 'card4' },
      { title: 'גילוי אש וכיבוי אוטומטי', to: 'card' },
      { title: 'מערכת מתזים/מערכת ספרינקלרים', to: 'card3' },
      { title: 'מז"ח  - מונע זרימה חוזרת', to: 'card5' },
      { title: 'מנדפים ומתקני סינון', to: 'card6' },
      { title: 'הסמכות', to: 'our-clients' },
    ],
    dir: 'rtl',
    textAlign: 'right',
  },
};
