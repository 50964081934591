import styled from 'styled-components';

export const ProjCont = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    @media (min-width: 768px) {
        flex-direction: row-reverse; 
    }
`;

export const ImgCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    @media (min-width: 768px) {
        width: 22.5%;
    }
    
`;

export const ProjImg = styled.img`
    width: 100%;
    cursor: pointer;
`;

export const TitleCont = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    width:90%;
    direction: rtl;
    @media (min-width: 768px) {
        width: 50%;
    }
`;
export const ProjText = styled.div`
    direction: rtl;
    width: 95%;
    text-align: right;
    @media (min-width: 768px) {
        width: 65%;
    }
`;
export const ProjImageDiv = styled.div`
    display: flex;
    width: 45%;
    padding: 5px;
    position: relative;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
        width: 35%;
        padding: 15px;
    }
`;

export const ProjImageText = styled.div`
    position: absolute;
    color: white;
`;